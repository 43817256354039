import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './styles';
import {
    Paper,
    Grid,
    Typography,
    TextField,
    Tabs,
    Tab,
    Fab,
    Table,
    TableBody,
    TableHead,
    IconButton,
    TableRow,
    TableCell,
} from '@material-ui/core';
import { Edit } from '@material-ui/icons';

const TabPanel = (props) => {
    const { arrayData, value, index, handleChangeTextField } = props;

    return (
        <div>
            {value === index && (
                <SubDealsCard
                    arrayData={arrayData}
                    handleChangeTextField={handleChangeTextField}
                    indexKeyObject={index}
                />
            )}
        </div>
    );
};

const SubDealsCard = (props) => {
    const { arrayData, handleChangeTextField, indexKeyObject } = props;
    const classes = useStyles();

    // State to track whether editing is enabled for each row
    const [editingRows, setEditingRows] = useState([]);

    const toggleEditing = (index) => {
        setEditingRows((prevEditingRows) => {
            const newEditingRows = [...prevEditingRows];
            newEditingRows[index] = !newEditingRows[index];
            return newEditingRows;
        });
    };

    return (
        <Grid container spacing={2} className={classes.grid}>
            <Grid container width='100%'>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Description</TableCell>
                            <TableCell>Price</TableCell>
                            <TableCell>Cost</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {arrayData.map((object, index) => (
                            <TableRow key={index}>
                                <TableCell>
                                    <TextField
                                        value={object.description}
                                        variant='outlined'
                                        className={classes.textField}
                                        fullWidth
                                        name='description'
                                        onChange={(e) =>
                                            handleChangeTextField(e, indexKeyObject, index)
                                        }
                                        disabled={!editingRows[index]}
                                    />
                                </TableCell>

                                <TableCell>
                                    <TextField
                                        value={object.price}
                                        variant='outlined'
                                        className={classes.textField}
                                        fullWidth
                                        name='price'
                                        onChange={(e) =>
                                            handleChangeTextField(e, indexKeyObject, index)
                                        }
                                        disabled={!editingRows[index]}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        label='Coste'
                                        value={object.coste}
                                        variant='outlined'
                                        className={classes.textField}
                                        fullWidth
                                        name='coste'
                                        onChange={(e) =>
                                            handleChangeTextField(e, indexKeyObject, index)
                                        }
                                        disabled={!editingRows[index]}
                                    />
                                </TableCell>
                                <TableCell>
                                    <IconButton
                                        onClick={() => {
                                            toggleEditing(index);
                                        }}
                                    >
                                        <Edit />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
    );
};

const View = (props) => {
    const {
        basicData,
        backData,
        frontData,
        indexTab,
        handleChangeTab,
        handleChangeTextField,
        onEdit,
    } = props;
    const classes = useStyles();

    const fieldOrder = [
        'Deal',
        'Date',
        'NeUs',
        'Associate Num ',
        'Associate 1',
        'Associate 2',
        'FIM',
        'Unit',
        'Special Finance',
    ];
    return (
        <Paper className={classes.paper}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Type</TableCell>
                                <TableCell>Value</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {fieldOrder.map((field, index) => {
                                const keyObject = Object.keys(basicData).find(
                                    (key) => basicData[key].title === field
                                );

                                if (
                                    !keyObject ||
                                    keyObject === 'payment_data' ||
                                    keyObject === '_id'
                                ) {
                                    return null;
                                }

                                return (
                                    <TableRow key={index}>
                                        <TableCell>
                                            <Typography>{basicData[keyObject].title}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                value={basicData[keyObject].value}
                                                name={keyObject}
                                                onChange={(e) => handleChangeTextField(e, 2, null)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {Object.keys(basicData).map((keyObject, index) => {
                                if (keyObject === 'HB') {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell>
                                                <Typography>HoldBack</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    value={basicData[keyObject].value}
                                                    name={keyObject}
                                                    onChange={(e) =>
                                                        handleChangeTextField(e, 2, null)
                                                    }
                                                />
                                            </TableCell>
                                        </TableRow>
                                    );
                                }
                            })}
                            {Object.keys(basicData).map((keyObject, index) => {
                                if (keyObject === 'DAP') {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell>
                                                <Typography>DAP</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    value={basicData[keyObject].value}
                                                    name={keyObject}
                                                    onChange={(e) =>
                                                        handleChangeTextField(e, 2, null)
                                                    }
                                                />
                                            </TableCell>
                                        </TableRow>
                                    );
                                }
                            })}
                            {Object.keys(basicData).map((keyObject, index) => {
                                if (keyObject === 'assoc_pre_comm') {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell>
                                                <Typography>
                                                    {basicData[keyObject].title}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    value={basicData[keyObject].value}
                                                    name={keyObject}
                                                    onChange={(e) =>
                                                        handleChangeTextField(e, 2, null)
                                                    }
                                                />
                                            </TableCell>
                                        </TableRow>
                                    );
                                }
                            })}
                        </TableBody>
                    </Table>
                </Grid>
                ;
            </Grid>

            <Paper className={classes.containerTabs}>
                <Tabs value={indexTab} onChange={handleChangeTab} aria-label='simple tabs example'>
                    <Tab label='Front' className={classes.tabs} />
                    <Tab label='Back' className={classes.tabs} />
                </Tabs>
            </Paper>
            <TabPanel
                value={indexTab}
                index={0}
                arrayData={frontData}
                handleChangeTextField={handleChangeTextField}
            />
            <TabPanel
                value={indexTab}
                index={1}
                arrayData={backData}
                handleChangeTextField={handleChangeTextField}
            />
            <Fab color='secondary' aria-label='edit' className={classes.fab} onClick={onEdit}>
                <Edit />
            </Fab>
        </Paper>
    );
};

View.propTypes = {
    basicData: PropTypes.object.isRequired,
    backData: PropTypes.array.isRequired,
    frontData: PropTypes.array.isRequired,
    indexTab: PropTypes.number.isRequired,
    handleChangeTab: PropTypes.func.isRequired,
    handleChangeTextField: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
};

export default View;
