import { get } from 'lodash';
import {
    WASHOUT_ASSOC_SEARCH_START,
    WASHOUT_ASSOC_SEARCH_COMPLETE,
    WASHOUT_ASSOC_SEARCH_ERROR,
    UPDATE_TABLE_SUBDEALS,
    WASHOUT_ASSOC_EDIT_START,
    CLEAN_WASHOUT,
    ALL_WASHOUT_COMPLETE,
} from '../../consts/actionTypes';

const tmColumns = [
    { title: 'Salesperson', field: 'associate_name' },
    { title: 'New', field: 'total_new_units', type: 'numeric' },
    { title: 'Used', field: 'total_used_units', type: 'numeric' },
    { title: 'Unit', field: 'total_units', type: 'numeric' },
    { title: 'Hold back', field: 'total_hb', type: 'numeric' },
    { title: 'Front Gross', field: 'total_front', type: 'numeric' },
    { title: 'Back Gross', field: 'total_back', type: 'numeric' },
    { title: 'F&B', field: 'total_front_back', type: 'numeric' },
    { title: '%F&B', field: 'total_comm_front_plus_back', type: 'numeric' },
];

const columnsBasic = [
    // { title: 'Date', field: 'DASO' },
    { title: 'Deal#', field: 'FIWI', type: 'numeric' },
    { title: 'Type', field: 'DETY' },
    //{ title: 'Associate number', field: 'assoc_number' },
    { title: 'ST', field: 'ST' },
    { title: 'SF', field: 'SF' },
    { title: 'SP1', field: 'SANO1' },
    { title: 'SP2', field: 'SANO2' },
    //{ title: 'FIM', field: 'FIMA' },
    { title: 'Customer Name', field: 'customer' },
    //{ title: 'Front Gross', field: 'deal_front', type: 'numeric' },
    { title: 'Back Gross', field: 'deal_back', type: 'numeric' },
    { title: 'Commission', field: 'comm_front_plus_back', type: 'numeric' },
];
//_profit
const businessManager = [
    { title: 'Reserve', field: 'reserve_profit' },
    { title: 'FIC', field: 'FICONA' },
    { title: 'WARR', field: 'mbi', type: 'numeric' },
    { title: 'VAL', field: 'mbi_profit', type: 'numeric' },
    { title: 'VREPL', field: 'vrepl', type: 'numeric' },
    { title: 'VAL', field: 'vrepl_profit', type: 'numeric' },
    { title: 'THEFTP', field: 'tra_theft', type: 'numeric' },
    { title: 'VAL', field: 'tra_theft_profit', type: 'numeric' },
    { title: 'VSHIELD', field: 'vshield', type: 'numeric' },
    { title: 'VAL', field: 'vshield_profit', type: 'numeric' },
    { title: 'GAP', field: 'gap', type: 'numeric' },
    { title: 'VAL', field: 'gap_profit', type: 'numeric' },
    { title: 'MAINT', field: 'maint', type: 'numeric' },
    { title: 'VAL', field: 'maint_profit', type: 'numeric' },
    { title: 'ADR', field: 'rsa', type: 'numeric' },
    { title: 'VAL', field: 'rsa_profit', type: 'numeric' },
    { title: 'KEYR', field: 'krepl', type: 'numeric' },
    { title: 'VAL', field: 'krepl_profit', type: 'numeric' },
    { title: 'ROADH', field: 'rhazard', type: 'numeric' },
    { title: 'VAL', field: 'rhazard_profit', type: 'numeric' },
    { title: 'NITRO', field: 'nitro', type: 'numeric' },
    { title: 'VAL', field: 'nitro_profit', type: 'numeric' },
    { title: 'PAINT_DENT', field: 'paint_dent', type: 'numeric' },
    { title: 'VAL', field: 'paint_dent_profit', type: 'numeric' },
    { title: 'AWHEEL', field: 'alloy', type: 'numeric' },
    { title: 'VAL', field: 'alloy_profit', type: 'numeric' },
    { title: 'EWT', field: 'ex_wear', type: 'numeric' },
    { title: 'VAL', field: 'ex_wear_profit', type: 'numeric' },
    { title: 'AUBU', field: 'aubu', type: 'numeric' },
    { title: 'VAL', field: 'aubu_profit', type: 'numeric' },
    { title: 'CDS-GPS', field: 'cds_gps', type: 'numeric' },
    { title: 'VAL', field: 'cds_gps_profit', type: 'numeric' },
    { title: 'APPEAR', field: 'appearance_pkg', type: 'numeric' },
    { title: 'VAL', field: 'appearance_pkg_profit', type: 'numeric' },
];

const productSpecialist = [
    { title: 'GP', field: 'FrontGP', type: 'numeric' },
    { title: 'Comm Front', field: 'comm_front', type: 'numeric' },
    { title: 'Comm Back', field: 'comm_back', type: 'numeric' },
    { title: 'Book Comm', field: 'assoc_pre_comm', type: 'numeric' },
    { title: 'Increase', field: 'increase', type: 'numeric' },
];
const initialState = {
    listResponseData: null,
    typeAssociate: null,
    listAllWashout: null,
    listResponseTotals: [],
    listResponseAdjustments: [],
    respondeBonus: 0,
    dms: null,
    date: null,
    typeAssociate: null,
    payroll: {
        New: 0,
        Used: 0,
        'Total Back Gross': 0,
        Product: 0,
        Level: '',
        Penetration: 0,
        'Subtotal Commission': 0,
        'Subtotal Bonus For Units Sold': 0,
        Subtotal: 0,
        Adjustment: 0,
        'Subtotal Plus Adjustmet': 0,
        Draw: 0,
        TOTAL: 0,
    },
    summary: {
        'Commission new car': '$0',
        'Commission used car': '$0',
        'Bonus For Units New': 0,
        'Bonus For Units Used': 0,
    },

    dinamicTableConf2: {
        columns: columnsBasic,
        data: [],
    },
    dinamicTableConf: {
        columns: columnsBasic,
        data: [],
    },
    dinamicTableConfNew: {
        columns: columnsBasic,
        data: [],
    },
    dinamicTableConfUsed: {
        columns: columnsBasic,
        data: [],
    },
    unit_adjustments: [],
};

const columnsCant = [
    { title: 'New', field: 'new', type: 'numeric' },
    { title: 'Used', field: 'used', type: 'numeric' },
    { title: 'Unit', field: 'unit', type: 'numeric' },
];
const columnNew = { title: 'Cant', field: 'new', type: 'numeric' };
const columnUsed = { title: 'Cant', field: 'used', type: 'numeric' };
const columnsSeller = { title: ' Commission', field: 'assoc_pre_comm', type: 'numeric' };
const columnsYuri = [
    { title: 'Warranty', field: 'Warranty', type: 'numeric' },
    { title: 'Theft', field: 'Theft', type: 'numeric' },
    { title: 'Nitro', field: 'Nitro', type: 'numeric' },
    { title: 'Comm Spiff', field: 'comm_spiff', type: 'numeric' },
];

export default function (state = initialState, action) {
    const dolar = (x) => {
        return `$${new Intl.NumberFormat().format(x)}`;
    };
    const percentage = (cant, unitTotal) => {
        const porce = (cant * 100) / unitTotal || 0;
        return `${cant}(${porce?.toFixed(2)}%)`;
    };
    const subTotalCommi = (array) => {
        let subTotal = 0;
        for (const obj of array) {
            subTotal = subTotal + Number(obj.comm_front_plus_back ? obj.comm_front_plus_back : 0);
        }
        return subTotal;
    };
    const increaseComi = (array) => {
        let increase = 0;
        for (const obj of array) {
            increase = increase + Number(obj.increase ? obj.increase : 0);
        }
        return increase;
    };
    const totalCommComi = (array) => {
        let totalComm = 0;
        for (const obj of array) {
            totalComm = totalComm + Number(obj.assoc_pre_comm ? obj.assoc_pre_comm : 0);
        }
        return totalComm;
    };
    const endRowTotal = (arrayData, totalsResponse, typeAssociate) => {
        let dataResponseTemp = [];
        let totalComm = 0;
        let totalDealFront = 0;
        let totalDealBack = 0;
        let totalAssoc_pre_comm = 0;
        let totalCommFront = 0;
        let totalIncrease = 0;
        let totalmbi = 0;
        let totalgap = 0;
        let totalrsa = 0;
        let totaltra_theft = 0;
        let totalex_wear = 0;
        let totalrhazard = 0;
        let totalpaint_dent = 0;
        let totalalloy = 0;
        let totalcomm_front = 0;
        let totalcomm_back = 0;
        let totalkrepl = 0;
        let totalnitro = 0;
        let totalVrepl = 0;
        let totalVreplProfit = 0;
        let totalvshield = 0;
        let totalmaint = 0;
        let totalreserve_profit = 0;
        let totalGP = 0;
        let totalMbi_profit = 0;
        let totalGap_profit = 0;
        let totalRsa_profit = 0;
        let totalTra_theft_profit = 0;
        let totalEx_wear_profit = 0;
        let totalRhazard_profit = 0;
        let totalPaint_dent_profit = 0;
        let totalAlloy_profit = 0;
        let totalKrepl_profit = 0;
        let totalNitro_profit = 0;
        let totalVshield_profit = 0;
        let totalMaint_profit = 0;
        let totalaubu_profit = 0;
        let totalaubu = 0;
        let total_count_cds_gps = 0;
        let total_cds_gps = 0;
        let total_count_appearance_pkg = 0;
        let total_appearance_pkg = 0;

        if (typeAssociate === 'TM') {
            let updateResponseData = [];

            for (let obj of arrayData) {
                updateResponseData = [
                    ...updateResponseData,
                    {
                        ...obj,
                        total_hb: dolar(obj?.total_hb) || 0,
                        total_front: dolar(obj?.total_front) || 0,
                        total_back: dolar(obj?.total_back) || 0,
                        total_front_back: dolar(obj?.total_front_back) || 0,
                        total_comm_front_plus_back: dolar(obj?.total_comm_front_plus_back) || 0,
                    },
                ];
            }

            const rowTotal = {
                associate_name: 'TOTAL',
                total_new_units: totalsResponse?.team_total_new_units,
                total_used_units: totalsResponse?.team_total_used_units,
                total_units: totalsResponse?.team_total_units,
                total_hb: dolar(totalsResponse?.team_total_hb) || 0,
                total_front: dolar(totalsResponse?.team_total_front) || 0,
                total_back: dolar(totalsResponse?.team_total_back) || 0,
                total_front_back: dolar(totalsResponse?.team_total_front_back) || 0,
                total_comm_front_plus_back:
                    dolar(totalsResponse?.team_total_comm_front_plus_back) || 0,
            };
            return [...updateResponseData, {}, rowTotal];
        }

        for (const objDataResTem of arrayData) {
            totalComm =
                totalComm +
                Number(objDataResTem.comm_front_plus_back ? objDataResTem.comm_front_plus_back : 0);
            totalDealFront =
                totalDealFront + Number(objDataResTem.deal_front ? objDataResTem.deal_front : 0);
            totalDealBack =
                totalDealBack + Number(objDataResTem.deal_back ? objDataResTem.deal_back : 0);
            totalAssoc_pre_comm =
                totalAssoc_pre_comm +
                Number(objDataResTem.assoc_pre_comm ? objDataResTem.assoc_pre_comm : 0);
            totalIncrease =
                totalIncrease + Number(objDataResTem.increase ? objDataResTem.increase : 0);
            totalcomm_front =
                totalcomm_front + Number(objDataResTem.comm_front ? objDataResTem.comm_front : 0);
            totalcomm_back =
                totalcomm_back + Number(objDataResTem.comm_back ? objDataResTem.comm_back : 0);
            totalGP += Number(
                (objDataResTem.deal_back ? parseFloat(objDataResTem.deal_back.toFixed(2)) : 0) +
                    (objDataResTem.deal_front ? parseFloat(objDataResTem.deal_front.toFixed(2)) : 0)
            );
            if (typeAssociate == 'Seller') {
                dataResponseTemp = [
                    ...dataResponseTemp,
                    {
                        ...objDataResTem,
                        deal_back: dolar(
                            objDataResTem.deal_back ? objDataResTem.deal_back.toFixed(2) : 0
                        ),
                        deal_front: dolar(
                            objDataResTem.deal_front ? objDataResTem.deal_front.toFixed(2) : 0
                        ),
                        comm_front: dolar(
                            objDataResTem.comm_front ? objDataResTem.comm_front.toFixed(2) : 0
                        ),
                        comm_front_plus_back: dolar(
                            objDataResTem.comm_front_plus_back
                                ? objDataResTem.comm_front_plus_back.toFixed(2)
                                : 0
                        ),
                        assoc_pre_comm: dolar(
                            objDataResTem.assoc_pre_comm ? objDataResTem.assoc_pre_comm : 0
                        ),
                        increase: dolar(
                            objDataResTem.increase ? objDataResTem.increase.toFixed(2) : 0
                        ),
                        comm_back: dolar(
                            objDataResTem.comm_back ? objDataResTem.comm_back.toFixed(2) : 0
                        ),
                        comm_spiff: dolar(
                            objDataResTem.comm_spiff ? objDataResTem.comm_spiff.toFixed(2) : 0
                        ),
                        FrontGP: dolar(
                            (objDataResTem.deal_back
                                ? parseFloat(objDataResTem.deal_back.toFixed(2))
                                : 0) +
                                (objDataResTem.deal_front
                                    ? parseFloat(objDataResTem.deal_front.toFixed(2))
                                    : 0)
                        ),
                    },
                ];
            } else {
                totalmbi = totalmbi + Number(objDataResTem.mbi ? objDataResTem.mbi : 0);
                totalgap = totalgap + Number(objDataResTem.gap ? objDataResTem.gap : 0);
                totalrsa = totalrsa + Number(objDataResTem.rsa ? objDataResTem.rsa : 0);
                totaltra_theft =
                    totaltra_theft + Number(objDataResTem.tra_theft ? objDataResTem.tra_theft : 0);
                totalex_wear =
                    totalex_wear + Number(objDataResTem.ex_wear ? objDataResTem.ex_wear : 0);
                totalrhazard =
                    totalrhazard + Number(objDataResTem.rhazard ? objDataResTem.rhazard : 0);
                totalpaint_dent =
                    totalpaint_dent +
                    Number(objDataResTem.paint_dent ? objDataResTem.paint_dent : 0);
                totalalloy = totalalloy + Number(objDataResTem.alloy ? objDataResTem.alloy : 0);
                totalkrepl = totalkrepl + Number(objDataResTem.krepl ? objDataResTem.krepl : 0);
                totalnitro = totalnitro + Number(objDataResTem.nitro ? objDataResTem.nitro : 0);
                totalVrepl = totalVrepl + Number(objDataResTem.vrepl || 0);
                totalVreplProfit = totalVreplProfit + Number(objDataResTem.vrepl_profit || 0);
                totalvshield =
                    totalvshield + Number(objDataResTem.vshield ? objDataResTem.vshield : 0);
                totalmaint = totalmaint + Number(objDataResTem.maint ? objDataResTem.maint : 0);
                totalreserve_profit =
                    totalreserve_profit +
                    Number(objDataResTem.reserve_profit ? objDataResTem.reserve_profit : 0);
                totalMbi_profit += Number(objDataResTem.mbi_profit ? objDataResTem.mbi_profit : 0);
                totalGap_profit += Number(objDataResTem.gap_profit ? objDataResTem.gap_profit : 0);
                totalRsa_profit += Number(objDataResTem.rsa_profit ? objDataResTem.rsa_profit : 0);
                totalTra_theft_profit += Number(
                    objDataResTem.tra_theft_profit ? objDataResTem.tra_theft_profit : 0
                );
                totalEx_wear_profit += Number(
                    objDataResTem.ex_wear_profit ? objDataResTem.ex_wear_profit : 0
                );
                totalRhazard_profit += Number(
                    objDataResTem.rhazard_profit ? objDataResTem.rhazard_profit : 0
                );
                totalPaint_dent_profit += Number(
                    objDataResTem.paint_dent_profit ? objDataResTem.paint_dent_profit : 0
                );
                totalAlloy_profit += Number(
                    objDataResTem.alloy_profit ? objDataResTem.alloy_profit : 0
                );
                totalKrepl_profit += Number(
                    objDataResTem.krepl_profit ? objDataResTem.krepl_profit : 0
                );
                totalNitro_profit += Number(
                    objDataResTem.nitro_profit ? objDataResTem.nitro_profit : 0
                );
                totalVshield_profit += Number(
                    objDataResTem.vshield_profit ? objDataResTem.vshield_profit : 0
                );
                totalMaint_profit += Number(
                    objDataResTem.maint_profit ? objDataResTem.maint_profit : 0
                );
                totalaubu_profit += Number(
                    objDataResTem.aubu_profit ? objDataResTem.aubu_profit : 0
                );
                totalaubu += Number(objDataResTem.aubu ? objDataResTem.aubu : 0);
                total_count_cds_gps += Number(objDataResTem.cds_gps ? objDataResTem.cds_gps : 0);

                total_cds_gps += Number(
                    objDataResTem.cds_gps_profit ? objDataResTem.cds_gps_profit : 0
                );

                total_count_appearance_pkg += Number(
                    objDataResTem.appearance_pkg ? objDataResTem.appearance_pkg : 0
                );

                total_appearance_pkg += Number(
                    objDataResTem.appearance_pkg_profit ? objDataResTem.appearance_pkg_profit : 0
                );

                dataResponseTemp = [
                    ...dataResponseTemp,
                    {
                        ...objDataResTem,
                        deal_back: dolar(
                            objDataResTem.deal_back ? objDataResTem.deal_back.toFixed(2) : 0
                        ),
                        deal_front: dolar(
                            objDataResTem.deal_front ? objDataResTem.deal_front.toFixed(2) : 0
                        ),
                        comm_front_plus_back: dolar(
                            objDataResTem.comm_front_plus_back
                                ? objDataResTem.comm_front_plus_back.toFixed(2)
                                : 0
                        ),
                        assoc_pre_comm: dolar(
                            objDataResTem.assoc_pre_comm ? objDataResTem.assoc_pre_comm : 0
                        ),
                        nitro: objDataResTem.nitro ? objDataResTem.nitro : 0,
                        vshield: objDataResTem.vshield ? objDataResTem.vshield : 0,
                        maint: objDataResTem.maint ? objDataResTem.maint : 0,
                        gap_profit: dolar(objDataResTem.gap_profit ? objDataResTem.gap_profit : 0),
                        maint_profit: dolar(
                            objDataResTem.maint_profit ? objDataResTem.maint_profit : 0
                        ),
                        mbi_profit: dolar(objDataResTem.mbi_profit ? objDataResTem.mbi_profit : 0),
                        vrepl_profit: dolar(objDataResTem.vrepl_profit || 0),
                        tra_theft_profit: dolar(
                            objDataResTem.tra_theft_profit ? objDataResTem.tra_theft_profit : 0
                        ),
                        rsa_profit: dolar(objDataResTem.rsa_profit ? objDataResTem.rsa_profit : 0),
                        krepl_profit: dolar(
                            objDataResTem.krepl_profit ? objDataResTem.krepl_profit : 0
                        ),
                        rhazard_profit: dolar(
                            objDataResTem.rhazard_profit ? objDataResTem.rhazard_profit : 0
                        ),
                        nitro_profit: dolar(
                            objDataResTem.nitro_profit ? objDataResTem.nitro_profit : 0
                        ),
                        vshield_profit: dolar(
                            objDataResTem.vshield_profit ? objDataResTem.vshield_profit : 0
                        ),
                        paint_dent_profit: dolar(
                            objDataResTem.paint_dent_profit ? objDataResTem.paint_dent_profit : 0
                        ),
                        alloy_profit: dolar(
                            objDataResTem.alloy_profit ? objDataResTem.alloy_profit : 0
                        ),
                        ex_wear_profit: dolar(
                            objDataResTem.ex_wear_profit ? objDataResTem.ex_wear_profit : 0
                        ),
                        reserve_profit: dolar(
                            objDataResTem.reserve_profit ? objDataResTem.reserve_profit : 0
                        ),
                        aubu_profit: dolar(
                            objDataResTem.aubu_profit ? objDataResTem.aubu_profit : 0
                        ),
                        cds_gps: objDataResTem.cds_gps ? objDataResTem.cds_gps : 0,
                        cds_gps_profit: dolar(
                            objDataResTem.cds_gps_profit ? objDataResTem.cds_gps_profit : 0
                        ),
                        appearance_pkg: objDataResTem.appearance_pkg
                            ? objDataResTem.appearance_pkg
                            : 0,
                        appearance_pkg_profit: dolar(
                            objDataResTem.appearance_pkg_profit
                                ? objDataResTem.appearance_pkg_profit
                                : 0
                        ),
                    },
                ];
            }
        }
        const row = arrayData[0];
        let rowTotal = {};
        for (const prop in row) {
            rowTotal = { ...rowTotal, [prop]: '' };
        }
        rowTotal = {
            ...rowTotal,
            new: totalsResponse[0]?.value,
            used: totalsResponse[1]?.value,
            other: totalsResponse[2]?.value,
            unit: totalsResponse[3]?.value,
            deal_front: dolar(totalDealFront.toFixed(2)),
            deal_back: dolar(totalDealBack.toFixed(2)),
            comm_front: dolar(totalcomm_front.toFixed(2)),
            comm_front_plus_back: dolar(totalComm.toFixed(2)),
            assoc_pre_comm: dolar(totalAssoc_pre_comm),
            increase: dolar(totalIncrease.toFixed(2)),
            comm_back: dolar(totalcomm_back.toFixed(2)),
            FrontGP: dolar(totalGP.toFixed(2)),
        };
        if (typeAssociate == 'FIM') {
            rowTotal = {
                ...rowTotal,
                mbi: totalmbi,
                gap: totalgap,
                rsa: totalrsa,
                tra_theft: totaltra_theft,
                ex_wear: totalex_wear,
                rhazard: totalrhazard,
                paint_dent: totalpaint_dent,
                alloy: totalalloy,
                comm_front_plus_back: dolar(totalComm.toFixed(2)),
                krepl: totalkrepl,
                nitro: totalnitro,
                vshield: totalvshield,
                maint: totalmaint,
                vrepl: totalVrepl,
                vrepl_profit: dolar(totalVreplProfit),
                mbi_profit: dolar(totalMbi_profit),
                gap_profit: dolar(totalGap_profit),
                rsa_profit: dolar(totalRsa_profit),
                tra_theft_profit: dolar(totalTra_theft_profit),
                ex_wear_profit: dolar(totalEx_wear_profit),
                rhazard_profit: dolar(totalRhazard_profit),
                paint_dent_profit: dolar(totalPaint_dent_profit),
                alloy_profit: dolar(totalAlloy_profit),
                krepl_profit: dolar(totalKrepl_profit),
                nitro_profit: dolar(totalNitro_profit),
                vshield_profit: dolar(totalVshield_profit),
                maint_profit: dolar(totalMaint_profit),
                reserve_profit: dolar(totalreserve_profit),
                aubu_profit: dolar(totalaubu_profit),
                aubu: totalaubu,
                cds_gps: total_count_cds_gps,
                cds_gps_profit: dolar(total_cds_gps),
                appearance_pkg: total_count_appearance_pkg,
                appearance_pkg_profit: dolar(total_appearance_pkg),
            };
        }

        dataResponseTemp = [...dataResponseTemp, rowTotal];

        return dataResponseTemp;
    };
    const calculateCommissionCars = (array, totalsR, bonusR, typeA) => {
        let commCar = [0, 0];
        for (const obj of array) {
            if (obj.NEUS === 'NEW') {
                commCar[0] = commCar[0] + (obj.comm_front_plus_back ? obj.comm_front_plus_back : 0);
            }
            if (obj.NEUS === 'USED') {
                commCar[1] = commCar[1] + (obj.comm_front_plus_back ? obj.comm_front_plus_back : 0);
            }
        }
        let summaryTemp = { ...state.summary };
        let valueSummary = 0;
        if (typeA === 'FIM') {
            for (const key in bonusR) {
                if (bonusR.hasOwnProperty(key)) {
                    valueSummary = bonusR[key];
                }
            }
            const key1 = 'Bonus For Units New';
            const key2 = 'Bonus For Units Used';
            let { [key2]: value2, [key1]: value1, ...otherFIM } = summaryTemp;
            summaryTemp = {
                ...otherFIM,
                'Commission new car': `${dolar(commCar[0].toFixed(2))}`,
                'Commission used car': `${dolar(commCar[1].toFixed(2))}`,
                'Extra Bonus': `${dolar(valueSummary)}`,
            };
        } else {
            summaryTemp = {
                ...summaryTemp,
                'Commission new car': `${dolar(commCar[0].toFixed(2))}`,
                'Commission used car': `${dolar(commCar[1].toFixed(2))}`,
                'Bonus For Units New': dolar(
                    Object.keys(bonusR).length == 2
                        ? Object.values(bonusR)[0]
                        : divideBonus(
                              Object.keys(bonusR).length > 0 ? Object.values(bonusR) : 0,
                              totalsR[0].value,
                              totalsR[3].value
                          )
                ),
                'Bonus For Units Used': dolar(
                    Object.keys(bonusR).length == 2
                        ? Object.values(bonusR)[1]
                        : divideBonus(
                              Object.keys(bonusR).length > 0 ? Object.values(bonusR) : 0,
                              totalsR[1].value,
                              totalsR[3].value
                          )
                ),
            };
        }

        return summaryTemp;
    };
    const calculateTotalAdjustment = (adjustmentR, type) => {
        let adjustmentTemp = 0;
        let drawTemp = 0;
        for (let obj of adjustmentR) {
            if (obj.CNTL2?.toLowerCase() === 'draw') {
                drawTemp += Number(obj.POSTAMTEXT);
            } else {
                adjustmentTemp += Number(obj.POSTAMTEXT);
            }
        }
        return type == 'adj' ? adjustmentTemp : drawTemp;
    };
    const calculateTotalPayroll = (bonusR, dataTableR, adjustmentR) => {
        let bonus = 0;
        // Object.keys(bonusR).length > 0 ? Number(Object.values(bonusR)) : 0
        if (Object.keys(bonusR).length === 1) {
            bonus = Number(Object.values(bonusR));
        } else if (Object.keys(bonusR).length === 2) {
            bonus = Number(
                Object.values(bonusR).reduce(function (a, b) {
                    return a + b;
                })
            );
        }
        let total =
            subTotalCommi(dataTableR) +
            calculateTotalAdjustment(adjustmentR, 'adj') +
            calculateTotalAdjustment(adjustmentR, 'dra') +
            bonus;
        return total.toFixed(2);
    };
    const divideBonus = (bonusT, unid, unidT) => {
        return ((bonusT / unidT) * unid).toFixed(0);
    };
    const loadValuesInPayroll = (
        totalsR,
        dataTableR,
        bonusR,
        adjustmentR,
        levelR,
        typeA,
        penetrationR,
        responseObj
    ) => {
        let payrollTemp = { ...initialState.payroll };

        let dataTableTotal = endRowTotal(dataTableR, totalsR, 'FIM');
        let endRowDataTableTotal = dataTableTotal.length - 1;
        let totalProduct =
            dataTableTotal[endRowDataTableTotal]?.mbi +
            dataTableTotal[endRowDataTableTotal]?.gap +
            dataTableTotal[endRowDataTableTotal]?.rsa +
            dataTableTotal[endRowDataTableTotal]?.tra_theft +
            dataTableTotal[endRowDataTableTotal]?.ex_wear +
            dataTableTotal[endRowDataTableTotal]?.rhazard +
            dataTableTotal[endRowDataTableTotal]?.paint_dent +
            dataTableTotal[endRowDataTableTotal]?.alloy +
            dataTableTotal[endRowDataTableTotal]?.krepl +
            dataTableTotal[endRowDataTableTotal]?.nitro +
            dataTableTotal[endRowDataTableTotal]?.vshield +
            dataTableTotal[endRowDataTableTotal]?.maint +
            dataTableTotal[endRowDataTableTotal]?.vrepl +
            dataTableTotal[endRowDataTableTotal]?.aubu+
            dataTableTotal[endRowDataTableTotal]?.cds_gps +
            dataTableTotal[endRowDataTableTotal]?.appearance_pkg;
        let totalGross =
            totalsR[7]?.value +
            totalsR[9]?.value +
            totalsR[11]?.value +
            totalsR[13]?.value +
            totalsR[15]?.value +
            totalsR[17]?.value +
            totalsR[19]?.value +
            totalsR[21]?.value +
            totalsR[23]?.value +
            totalsR[25]?.value +
            totalsR[27]?.value +
            totalsR[29]?.value +
            totalsR[33]?.value;
        let subTotal =
            Number(subTotalCommi(dataTableR)) +
            Number(Object.keys(bonusR).length > 0 ? Object.values(bonusR) : 0);
        let subTotalPlusAdj = subTotal + Number(calculateTotalAdjustment(adjustmentR, 'adj'));
        if (typeA === 'TM') {
            const unitVolumenBonus = responseObj.bonus['Unit Volume Bonus'];
            console.log(
                totalsR?.team_total_comm_front_plus_back,
                calculateTotalAdjustment(adjustmentR, 'dra'),
                totalsR?.team_total_comm_front_plus_back +
                    unitVolumenBonus +
                    calculateTotalAdjustment(adjustmentR, 'dra') +
                    calculateTotalAdjustment(adjustmentR, 'adj')
            );

            payrollTemp = {
                'Total F&B  + HB': dolar(
                    totalsR?.team_total_front_back + totalsR?.team_total_hb || 0
                ),
                Level: levelR,
                'Subtotal Commission': dolar(totalsR?.team_total_comm_front_plus_back),
                'Unit Volume Bonus': dolar(unitVolumenBonus),
                Adjustment: dolar(calculateTotalAdjustment(adjustmentR, 'adj')),
                'Subtotal Plus Adjustment': dolar(
                    totalsR?.team_total_comm_front_plus_back +
                        unitVolumenBonus +
                        calculateTotalAdjustment(adjustmentR, 'adj')
                ),
                Draw: dolar(calculateTotalAdjustment(adjustmentR, 'draw')),
                TOTAL: dolar(
                    totalsR?.team_total_comm_front_plus_back +
                        unitVolumenBonus +
                        calculateTotalAdjustment(adjustmentR, 'draw') +
                        calculateTotalAdjustment(adjustmentR, 'adj')
                ),
            };

            return payrollTemp;
        }

        if (typeA === 'FIM') {
            payrollTemp = {
                'Total Core Products': dolar(totalsR[7]?.value || 0),
                'Total Other Products': dolar(totalsR[8]?.value || 0),
                'Total Finance Reserve': dolar(totalsR[9]?.value || 0),
                'Total Products': dolar(totalsR[10]?.value),
                New: percentage(totalsR[0]?.value || 0, totalsR[3]?.value || 0),
                Used: percentage(totalsR[1]?.value || 0, totalsR[3]?.value || 0),
                Product: totalProduct,
                Level: levelR,
                Penetration: `${penetrationR}%`,
                'Subtotal Commission': dolar(subTotalCommi(dataTableR)),
                'Extra Bonus': dolar(Object.keys(bonusR).length > 0 ? Object.values(bonusR) : 0),
                Subtotal: dolar(subTotal),
                Adjustment: dolar(calculateTotalAdjustment(adjustmentR, 'adj')),
                'Subtotal Plus Adjustmet': dolar(subTotalPlusAdj),
                Draw: dolar(calculateTotalAdjustment(adjustmentR, 'dra')),
                TOTAL: dolar(calculateTotalPayroll(bonusR, dataTableR, adjustmentR)),
            };
        } else {
            const key3 = 'Total Back Gross';
            let { [key3]: value3, Level, Product, Penetration, ...other } = payrollTemp;
            let subTotal =
                Number(subTotalCommi(dataTableR)) +
                Number(
                    Object.keys(bonusR).length == 2
                        ? Object.values(bonusR)[0] + Object.values(bonusR)[1]
                        : Object.keys(bonusR).length > 0
                        ? Object.values(bonusR)
                        : 0
                );
            let subTotalPlusAdj = subTotal + Number(calculateTotalAdjustment(adjustmentR, 'adj'));
            payrollTemp = {
                ...other,
                New: percentage(totalsR[0]?.value, totalsR[3]?.value),
                Used: percentage(totalsR[1]?.value, totalsR[3]?.value),
                'Subtotal Commission': dolar(subTotalCommi(dataTableR)),

                'Total New units': totalsR[0]?.value,
                'Total Used units': totalsR[1]?.value,
                'Book Commission': dolar(totalCommComi(dataTableR)),
                'Total Increase': dolar(increaseComi(dataTableR)),

                'Subtotal Bonus For Units Sold': dolar(
                    Object.keys(bonusR).length == 2
                        ? Object.values(bonusR)[0] + Object.values(bonusR)[1]
                        : Object.keys(bonusR).length > 0
                        ? Object.values(bonusR)
                        : 0
                ),
                Subtotal: dolar(subTotal),
                Adjustment: dolar(calculateTotalAdjustment(adjustmentR, 'adj')),
                'Subtotal Plus Adjustmet': dolar(subTotalPlusAdj),
                Draw: dolar(calculateTotalAdjustment(adjustmentR, 'dra')),
                TOTAL: dolar(calculateTotalPayroll(bonusR, dataTableR, adjustmentR)),
            };
        }
        return payrollTemp;
    };
    const columnsForTypeAssociate = (typeAssociate, codeDms) => {
        let colmunsbase = [...state.dinamicTableConf.columns];

        if (typeAssociate == 'Seller') {
            colmunsbase = [
                ...colmunsbase.slice(0, 2),
                ...columnsCant,
                ...colmunsbase.slice(2, 6),
                { title: 'FIM', field: 'FIMA' },
                ...colmunsbase.slice(6, 7),
                { title: 'Front Gross', field: 'deal_front', type: 'numeric' },
                ...colmunsbase.slice(7, 8),
                ...productSpecialist,
                ...colmunsbase.slice(8, colmunsbase.length + 1),
            ];
        }
        if (codeDms == '1537') {
            colmunsbase = [
                ...colmunsbase.slice(0, 17),
                // ...columnsYuri,
                ...colmunsbase.slice(17, colmunsbase.length + 1),
            ];
        }
        if (typeAssociate == 'FIM') {
            colmunsbase = [
                ...colmunsbase.slice(0, 2),
                ...columnsCant,
                ...colmunsbase.slice(2, 10),
                ...businessManager,
                ...colmunsbase.slice(10, colmunsbase.length + 1),
            ];
        }
        return colmunsbase;
    };
    const addColumnTypeCar = (type, typeA) => {
        let newColumns = [];
        if (typeA === 'FIM') {
            if (type === 'New') {
                newColumns = [
                    ...columnsBasic.slice(0, 2),
                    columnNew,
                    ...columnsBasic.slice(2, 6),
                    { title: 'FIM', field: 'FIMA' },
                    ...columnsBasic.slice(6, 7),
                    { title: 'Front Gross', field: 'deal_front', type: 'numeric' },
                    ...columnsBasic.slice(7, 8),
                    ...productSpecialist,
                    ...columnsBasic.slice(8, columnsBasic.length + 1),
                    ...businessManager,
                ];
            } else {
                newColumns = [
                    ...columnsBasic.slice(0, 2),
                    columnUsed,
                    ...columnsBasic.slice(2, 6),
                    { title: 'FIM', field: 'FIMA' },
                    ...columnsBasic.slice(6, 7),
                    { title: 'Front Gross', field: 'deal_front', type: 'numeric' },
                    ...columnsBasic.slice(7, 8),
                    ...productSpecialist,
                    ...columnsBasic.slice(8, columnsBasic.length + 1),
                    ...businessManager,
                ];
            }
        } else {
            if (type === 'New') {
                newColumns = [
                    ...columnsBasic.slice(0, 2),
                    columnNew,
                    ...columnsBasic.slice(2, 6),
                    { title: 'FIM', field: 'FIMA' },
                    ...columnsBasic.slice(6, 7),
                    { title: 'Front Gross', field: 'deal_front', type: 'numeric' },
                    ...columnsBasic.slice(7, 8),
                    ...productSpecialist,
                    ...columnsBasic.slice(8, columnsBasic.length + 1),
                ];
            } else {
                newColumns = [
                    ...columnsBasic.slice(0, 2),
                    columnUsed,
                    ...columnsBasic.slice(2, 6),
                    { title: 'FIM', field: 'FIMA' },
                    ...columnsBasic.slice(6, 7),
                    { title: 'Front Gross', field: 'deal_front', type: 'numeric' },
                    ...columnsBasic.slice(7, 8),
                    ...productSpecialist,
                    ...columnsBasic.slice(8, columnsBasic.length + 1),
                ];
            }
        }
        return newColumns;
    };

    switch (action.type) {
        case WASHOUT_ASSOC_SEARCH_START:
            return {
                ...state,
                dms: get(action, 'dms'),
                date: get(action, 'date'),
                typeAssociate: get(action, 'typeAssociate'),
                listResponseTotals: [],
                listResponseAdjustments: [],
                unit_adjustments: [],
                dinamicTableConf: {
                    ...state.dinamicTableConf,
                    data: [],
                    columns: initialState.dinamicTableConf.columns,
                },
                dinamicTableConfUsed: {
                    ...state.dinamicTableConf,
                    data: [],
                    columns: initialState.dinamicTableConfUsed.columns,
                },
                dinamicTableConfNew: {
                    ...state.dinamicTableConf,
                    data: [],
                    columns: initialState.dinamicTableConfNew.columns,
                },
            };
        case WASHOUT_ASSOC_SEARCH_COMPLETE:
            const totalsResponse = get(action, 'results.data.totals');
            const dataTableResponse = get(action, 'results.data.data');
            const adjustmentResponse = get(action, 'results.data.adjustments');
            const bonusResponse = get(action, 'results.data.bonus');
            const dataTableResponseNew = get(action, 'resultsNew.data.data', []);
            const dataTableResponseUsed = get(action, 'resultsUsed.data.data', []);
            const response = get(action, 'results.data');
            const levelResponse = action.results.data.level;
            const penetrationResponse = action.results.data.penetration;

            return {
                ...state,
                listResponseData: get(action, 'results.data.data'),
                respondeBonus: get(action, 'results.data.christmast_bonus'),
                dinamicTableConf: {
                    ...state.dinamicTableConf,
                    columns: action.isTM
                        ? tmColumns
                        : columnsForTypeAssociate(
                              state.typeAssociate === '0' ? 'Seller' : 'FIM',
                              state.dms
                          ),
                    data: endRowTotal(
                        dataTableResponse,
                        totalsResponse,
                        state.typeAssociate === '0'
                            ? 'Seller'
                            : state.typeAssociate === '1'
                            ? 'FIM'
                            : 'TM'
                    ),
                },
                dinamicTableConf2: {
                    ...state.dinamicTableConf,
                    columns: action.isTM ? tmColumns : columnsForTypeAssociate(null, null),
                    data: endRowTotal(dataTableResponse, totalsResponse, null),
                },
                dinamicTableConfNew: {
                    columns: action.isTM
                        ? tmColumns
                        : addColumnTypeCar('New', state.typeAssociate == '0' ? 'Seller' : 'FIM'),
                    data: endRowTotal(
                        dataTableResponseNew,
                        totalsResponse,
                        state.typeAssociate == '0' ? 'Seller' : 'FIM'
                    ),
                },
                dinamicTableConfUsed: {
                    columns: action.isTM
                        ? tmColumns
                        : addColumnTypeCar('Used', state.typeAssociate == '0' ? 'Seller' : 'FIM'),
                    data: endRowTotal(
                        dataTableResponseUsed,
                        totalsResponse,
                        state.typeAssociate == '0' ? 'Seller' : 'FIM'
                    ),
                },
                listResponseTotals: totalsResponse,
                summary: calculateCommissionCars(
                    dataTableResponse,
                    totalsResponse,
                    bonusResponse,
                    state.typeAssociate == '0' ? 'Seller' : 'FIM'
                ),
                payroll: loadValuesInPayroll(
                    totalsResponse,
                    dataTableResponse,
                    bonusResponse,
                    adjustmentResponse,
                    levelResponse,
                    state.typeAssociate === '0' ? 'Seller' : action.isTM ? 'TM' : 'FIM',
                    penetrationResponse,
                    response
                ),
                unit_adjustments: action.results.data.unit_adjustments,
            };
        case WASHOUT_ASSOC_SEARCH_ERROR:
            return { ...state };
        case UPDATE_TABLE_SUBDEALS:
            return { ...state, listResponseTotals: get(action, 'payload') };
        case WASHOUT_ASSOC_EDIT_START:
            const oldData = get(action, 'oldData');
            const newData = get(action, 'newData');
            const editDataTemp = [...state.dinamicTableConf.data];
            editDataTemp[editDataTemp.indexOf(oldData)] = newData;
            return {
                ...state,
                dinamicTableConf: { ...state.dinamicTableConf, data: editDataTemp },
            };
        case CLEAN_WASHOUT:
            return { ...state, ...initialState };
        case ALL_WASHOUT_COMPLETE:
            let arrayAllWashout = action.results.data;
            let arrayAllWashoutNew = action.resultsNew.data;
            let arrayAllWashoutUsed = action.resultsUsed.data;
            let lisAssociateState = action.listAssociate;
            let adjustmentConfState = action.adjustmentConf;
            adjustmentConfState = {
                ...adjustmentConfState,
                columns: adjustmentConfState.columns.slice(1),
            };
            let tempArrayListResponse = [];
            const dataAssociate = (dms) => {
                let associate = {
                    code: '',
                    type: '',
                    name: '',
                };

                for (const array of lisAssociateState) {
                    let dataAssociate = array.filter((obj) => obj.dms_number == dms);
                    if (dataAssociate.length > 0) {
                        associate = {
                            code: dms,
                            type: dataAssociate[0].type,
                            name: dataAssociate[0].name,
                        };
                        break;
                    }
                }

                return associate;
            };
            for (const objArray of arrayAllWashout) {
                if (objArray.data.length > 0) {
                    const totalsResponseAll = objArray.totals;
                    const dataTableResponseAll = objArray.data;
                    const bonusResponseAll = objArray.bonus;
                    const adjustmentResponseAll = objArray.adjustments;
                    const lavelResponseAll = objArray.level;
                    const penetrationResponseAll = objArray.penetration;
                    let newObjArray = {
                        associateNumber: dataAssociate(objArray.data[0].assoc_number),
                        level: objArray.level,
                        dinamicTableConf: {
                            ...state.dinamicTableConf,
                            columns: columnsForTypeAssociate(
                                dataAssociate(objArray.data[0].assoc_number).type,
                                objArray.data[0].assoc_number
                            ),
                            data: endRowTotal(
                                dataTableResponseAll,
                                totalsResponseAll,
                                dataAssociate(objArray.data[0].assoc_number).type
                            ),
                        },
                        dinamicTableConf2: {
                            ...state.dinamicTableConf,
                            columns: columnsForTypeAssociate(null, null),
                            data: endRowTotal(dataTableResponseAll, totalsResponseAll, null),
                        },
                        dataTableAdjustment: {
                            ...adjustmentConfState,
                            data: adjustmentResponseAll,
                        },
                        respondeBonus: objArray.christmast_bonus,
                        listResponseTotals: totalsResponseAll,
                        summary: calculateCommissionCars(
                            dataTableResponseAll,
                            totalsResponseAll,
                            bonusResponseAll,
                            dataAssociate(objArray.data[0].assoc_number)
                        ),
                        payroll: loadValuesInPayroll(
                            totalsResponseAll,
                            dataTableResponseAll,
                            bonusResponseAll,
                            adjustmentResponseAll,
                            lavelResponseAll,
                            dataAssociate(objArray.data[0].assoc_number).type,
                            penetrationResponseAll
                        ),
                    };
                    tempArrayListResponse = [...tempArrayListResponse, newObjArray];
                }
            }

            const addDataTable = (arrayDataAll, nameKey, neus, list) => {
                let tempList = [...list];
                for (const objArrayData of arrayDataAll) {
                    if (objArrayData.data.length > 0) {
                        let associateNumber = objArrayData.data[0].assoc_number;
                        let index = list.findIndex(
                            (tempObj) => tempObj.associateNumber.code === associateNumber
                        );
                        if (index >= 0) {
                            let objArrayTemp = { ...list[index] };
                            objArrayTemp = {
                                ...objArrayTemp,
                                [nameKey]: {
                                    columns: addColumnTypeCar(neus),
                                    data: endRowTotal(
                                        objArrayData.data,
                                        objArrayTemp.listResponseTotals,
                                        dataAssociate(associateNumber).type
                                    ),
                                },
                            };
                            tempList = [
                                ...tempList.slice(0, index),
                                objArrayTemp,
                                ...tempList.slice(index + 1),
                            ];
                        }
                    }
                }
                return tempList;
            };

            return {
                ...state,
                listAllWashout: addDataTable(
                    arrayAllWashoutNew,
                    'dinamicTableConfNew',
                    'New',
                    addDataTable(
                        arrayAllWashoutUsed,
                        'dinamicTableConfUsed',
                        'Used',
                        tempArrayListResponse
                    )
                ),
            };

        default:
            return { ...state };
    }
}
