import { put, call, takeLatest, select } from 'redux-saga/effects';
import {
    REPORT_TOTALS_START,
    REPORT_TOTALS_COMPLETE,
    REPORT_SALES_START,
    REPORT_SALES_COMPLETE,
    FETCH_REPORT_PAYOUT_START,
    FETCH_REPORT_PAYOUT_COMPLETE,
    FETCH_REPORT_TMS_START,
    FETCH_REPORT_TMS_COMPLETE,
    FETCH_REPORT_TMS_SALES_START,
    FETCH_REPORT_TMS_SALES_COMPLETE,
} from '../../consts/actionTypes';
import { isLoadingAction, snackBarAction } from '../actions/utilitiesAction';
import { apiCall } from '../api';
import {
    REPORT_TOTALS_1,
    REPORT_SALES_1,
    REPORT_PAYOUT_1,
    REPORT_TOTALS_TM,
    REPORT_TOTALS_ALL_PLUS_TM,
} from '../../consts/urls';
import { token } from '../selectors';
import { cleanReportsAction } from '../../redux/actions/reportsActions';
export function* totals({ data }) {
    try {
        const responseToken = yield select(token);
        const results = yield call(
            apiCall,
            `${REPORT_TOTALS_1}/${data.month}${data.year}/${data.type}`,
            null,
            responseToken,
            'GET'
        );
        yield put({ type: REPORT_TOTALS_COMPLETE, results });
    } catch (error) {
        console.log(error);
        /*  const errorResponse = error.response;
          yield put({ type: REPORT_TOTALS_ERROR, errorResponse })
          yield put(snackBarAction(
              {
                  open: true,
                  msg: 'error Load List: ' + errorResponse.data.message,
                  type: 'error'
              }
          ));*/
    }
    yield put(isLoadingAction(false));
}
export function* sales({ data }) {
    try {
        const responseToken = yield select(token);
        const results = yield call(
            apiCall,
            `${REPORT_SALES_1}/${data.month}${data.year}`,
            null,
            responseToken,
            'GET'
        );
        yield put({ type: REPORT_SALES_COMPLETE, results });
        yield put(
            snackBarAction({
                open: true,
                msg: results.data.message,
                type: 'success',
            })
        );
    } catch (error) {
        console.log(error);
        /* const errorResponse = error.response;
         yield put(snackBarAction(
             {
                 open: true,
                 msg: 'error create associate: ' + errorResponse.data.message,
                 type: 'error'
             }
         ));
         */
    }
    yield put(isLoadingAction(false));
}

function* fetchReportPayout({ data }) {
    try {
        const responseToken = yield select(token);
        const results = yield call(
            apiCall,
            `${REPORT_PAYOUT_1}/${data.month}${data.year}/${data.type}`,
            null,
            responseToken,
            'GET'
        );

        yield put({ type: FETCH_REPORT_PAYOUT_COMPLETE, results });
    } catch (error) {
        yield put(
            snackBarAction({
                open: true,
                msg: error.response?.data?.message || 'Something went wrong.',
                type: 'error',
            })
        );
    }
    yield put(isLoadingAction(false));
}
function* fetchReportTMS({ data }) {
    try {
        const responseToken = yield select(token);
        const results = yield call(
            apiCall,
            `${REPORT_TOTALS_TM}/${data.month}${data.year}`,
            null,
            responseToken,
            'GET'
        );
        yield put({ type: FETCH_REPORT_TMS_COMPLETE, results });
    } catch (error) {
        yield put(cleanReportsAction());
        yield put(
            snackBarAction({
                open: true,
                msg: error.response?.data?.message || 'Something went wrong.',
                type: 'error',
            })
        );
    }
    yield put(isLoadingAction(false));
}

function* fetchReportTMSSales({ data }) {
    try {
        const responseToken = yield select(token);
        const results = yield call(
            apiCall,
            `${REPORT_TOTALS_ALL_PLUS_TM}/${data.month}${data.year}`,
            null,
            responseToken,
            'GET'
        );
        yield put({ type: FETCH_REPORT_TMS_SALES_COMPLETE, results });
    } catch (error) {
        yield put(cleanReportsAction());
        yield put(
            snackBarAction({
                open: true,
                msg: error.response?.data?.message || 'Something went wrong.',
                type: 'error',
            })
        );
    }
    yield put(isLoadingAction(false));
}

export default function* reports() {
    yield takeLatest(REPORT_TOTALS_START, totals);
    yield takeLatest(REPORT_SALES_START, sales);
    yield takeLatest(FETCH_REPORT_PAYOUT_START, fetchReportPayout);
    yield takeLatest(FETCH_REPORT_TMS_START, fetchReportTMS);
    yield takeLatest(FETCH_REPORT_TMS_SALES_START, fetchReportTMSSales);
}
