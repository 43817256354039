import { get } from 'lodash';
import {
    ADJUSTMENT_LIST_COMPLETE,
    ADJUSTMENT_LIST_ERROR,
    ADJUSTMENT_SINGLE_COMPLETE,
    ADJUSTMENT_SINGLE_ERROR,
    ADJUSTMENT_CREATE_COMPLETE,
    ADJUSTMENT_CREATE_ERROR,
    ADJUSTMENT_UPDATE_COMPLETE,
    ADJUSTMENT_UPDATE_ERROR,
    ADJUSTMENT_DELETE_COMPLETE,
    ADJUSTMENT_DELETE_ERROR,
    ADJUSTMENT_OPEN_DILOG,
    ADJUSTMENT_SINGLE,
    ADJUSTMENT_LIST_WASHOUT,
    CLEAN_ADJUSTMENT_WASHOUT,
} from '../../consts/actionTypes';

const adjustmentTable = {
    columns: [
        { title: 'Acct', field: 'ACCT' },
        { title: 'Description ', field: 'CNTL2' },
        {
            title: 'NEW/USED',
            field: 'NEW_USED',
            lookup: { NEW: 'NEW', USED: 'USED' },
        },
        { title: 'Amount', field: 'POSTAMTEXT' },
    ],
    data: [],
};
const initialState = {
    adjustmentTable: adjustmentTable,
    listResponse: null,
    singleResponse: null,
    openDialogAction: false,
    actionDialog: 0,
    dinamicTableConf: {
        ...adjustmentTable,
        columns: [
            { title: 'Associate', field: 'CNTL' },
            { title: 'Date', field: 'ADJ_DATE', defaultSort: 'desc' },
            ...adjustmentTable.columns,
        ],
    },
    associateWashout: { ...adjustmentTable },
};

const returnDolar = (x) => {
    if (!x.length) return [];

    for (const objAdjustment of x) {
        objAdjustment.POSTAMTEXT = `$${new Intl.NumberFormat().format(objAdjustment.POSTAMTEXT)}`;
    }
    return x;
};

export default function (state = initialState, action) {
    switch (action.type) {
        case ADJUSTMENT_LIST_COMPLETE:
            return {
                ...state,
                listResponse: get(action, 'results.data'),
                dinamicTableConf: {
                    ...state.dinamicTableConf,
                    data: [...returnDolar(action?.results?.data)],
                },
            };
        case ADJUSTMENT_LIST_ERROR:
            return { ...state, listResponse: null };
        case ADJUSTMENT_SINGLE_COMPLETE:
            return { ...state, singleResponse: get(action, 'results.data') };
        case ADJUSTMENT_SINGLE_ERROR:
            return { ...state, singleResponse: null };
        case ADJUSTMENT_CREATE_COMPLETE:
            return { ...state, listResponse: null };
        case ADJUSTMENT_CREATE_ERROR:
            return { ...state };
        case ADJUSTMENT_UPDATE_COMPLETE:
            return { ...state, listResponse: null };
        case ADJUSTMENT_UPDATE_ERROR:
            return { ...state };
        case ADJUSTMENT_DELETE_COMPLETE:
            return { ...state, listResponse: null };
        case ADJUSTMENT_DELETE_ERROR:
            return { ...state };
        case ADJUSTMENT_OPEN_DILOG:
            return {
                ...state,
                openDialogAction: get(action, 'open'),
                actionDialog: get(action, 'action'),
            };
        case ADJUSTMENT_SINGLE:
            return { ...state, singleResponse: null };
        case ADJUSTMENT_LIST_WASHOUT:
            //let disableCntl = { ...state.associateWashout.columns[4], editable: 'never' };
            let columnstemp = [...state.associateWashout.columns];

            //columnstemp[4] = { ...disableCntl };
            const totalAdjustmentAmount = action.adjustment?.reduce(
                (accu, next) => accu + Number(next.POSTAMTEXT),
                0
            );

            let associateWashoutTemp = {
                ...state.associateWashout,
                columns: [...columnstemp],
                data: [
                    ...returnDolar(action.adjustment),
                    {
                        ACCT: 'Total',
                        CNTL2: '',
                        POSTAMTEXT: '$' + String(totalAdjustmentAmount.toFixed(2)),
                    },
                ],
            };
            return {
                ...state,
                associateWashout: associateWashoutTemp,
            };
        case CLEAN_ADJUSTMENT_WASHOUT:
            return { ...state, associateWashout: { ...initialState.associateWashout } };
        default:
            return { ...state };
    }
}
